.ant-menu {
  background: $color-white;
  &-item {
    color: $color-text-primary !important;

    &-selected {
      background: $color-bg-acitve !important;
      font-weight: bold;
    }
  }
}
