.custom-tag {
  @apply bg-white rounded-lg;
  &.ant-tag {
    @apply bg-white;
  }

  svg {
    @apply w-3 h-3;
  }
}
