.header-container-button {
  display: flex;
  height: 65px;
  padding: 10px;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.blue-button-post {
  margin-left: 32px;
  height: 40px;
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Primary-6, #1890ff);
  background: var(--Primary-6, #1890ff);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  color: var(--character-primary-inverse, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.red-button-post {
  margin-left: 32px;
  height: 40px;
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Character-danger, #ff4d4f);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  color: var(--character-primary-inverse, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}

.header-title-post {
  color: #1c1f35;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.hashtag-line-post {
  margin-top: 8px;
  display: flex;
  padding: 7px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: var(---HitBox, rgba(255, 255, 255, 0));
}

.hashtag-post {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 3px;
  border-radius: 2px;
  border: 1px solid var(--Neutral-5, #d9d9d9);
  background: var(--Neutral-2, #fafafa);
}

.post-detail p {
  color: #666c89;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 151.523%; /* 24.244px */
}

.post-detail h1,
.post-detail h2,
.post-detail h3 {
  color: #1c1f35;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 24px;
  padding-bottom: 8px;
}

.post-detail figure {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 6px;
}

.image-post {
  display: flex;
  flex-direction: horizontal;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.training-post {
  blockquote {
    font-style: italic;
    padding: 4px 2em;
    margin: 4px 0;
    border-left: 5px solid #ccc;
  }

  ol {
    counter-reset: list-counter;
    padding-left: 16px;
    margin: 8px 0;
    list-style-type: none;
  }

  ul {
    counter-reset: list-counter;
    padding-left: 16px;
    margin: 8px 0;
    list-style-type: none;
  }

  ol li {
    position: relative;
    margin-bottom: 0.5em;
    line-height: 1.6;
    font-size: 1rem;
    color: #333;
    counter-increment: list-counter;
  }

  // h2::before,
  // h3::before,
  // h4::before {
  //   content: counter(list-counter) '. ';
  //   color: #1c1f35;
  //   font-family: Roboto;
  //   font-size: 32px;
  //   font-style: normal;
  //   font-weight: 600;
  //   line-height: normal;
  // }

  ol li span::before {
    content: counter(list-counter) '. ';
    color: #666c89;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 151.523%;
  }

  ol li::before {
    content: counter(list-counter) '. ';
    color: #666c89;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 151.523%;
  }

  ul li::before {
    content: '• ';
    color: #666c89;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 151.523%;
    padding-right: 4px;
  }

  ol li {
    color: #666c89;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 151.523%;
    text-align: justify;
    align-self: stretch;
  }

  ul li {
    color: #666c89;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 151.523%;
    text-align: justify;
    align-self: stretch;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 18px;
    text-align: left;
    background-color: #f9f9f9;
  }

  th,
  td {
    padding: 12px 15px;
    border: 1px solid #ddd;
  }

  th {
    background-color: #4caf50;
    color: white;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th,
  td {
    text-align: center;
  }

  a {
    color: #0073e6;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s ease;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: 151.523%;
  }

  a:hover {
    color: #005bb5;
    text-decoration: underline;
  }
}

.custom-modal {
  .ant-modal-content {
    padding: 0;
    border-radius: 5px;
  }
  .ant-modal-header,
  .ant-modal-footer,
  .ant-modal-body {
    padding: 20px;
  }
  .ant-modal-header {
    background-color: #d7242f;
    color: #fff !important;
    border-bottom: 1px solid #d9d9d9;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .ant-modal-title {
    color: #fff;
  }

  .ant-modal-close-x {
    color: #fff;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  .ant-btn {
    border-radius: 5px;
    font-size: 14px;
    padding: 6px 16px;
    min-width: 50px;
    transition: all 0.3s ease;
  }

  .ant-btn-primary {
    background-color: #d7242f;
    border-color: #d7242f;
    &:hover {
      background-color: #d7242f;
      border-color: #d7242f;
    }
  }

  .ant-btn-default {
    color: #333;
    border-color: #d9d9d9;
    &:hover {
      color: #d7242f;
      border-color: #d7242f;
    }
  }

  .ant-btn[disabled] {
    background-color: #f5f5f5;
    color: #999;
    cursor: not-allowed;
  }
}

.expected-day-training {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
