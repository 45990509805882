.popup_sendmail{
   
    .ant-modal-content{
        padding: 0  !important;
        width: 560px;
        height: 350px;
        border-radius: 8px ;
        overflow: hidden;
       
    }
    .ant-modal-close{
        display: none;
    }
    .ant-radio-inner{
        border-color: #1890FF  !important;
        background-color: white !important;
    }
    .ant-radio-inner:hover{
        border-color: #1890FF !important;
        background-color: white !important;
    }
    .ant-radio-inner::after{
        background-color: #1890FF !important;
     
    }
    .ant-modal-footer{
        display: none;
    }
    .ant-row  {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap:20px;
       justify-content: center;

    }


}