.input__search {
  min-width: 546px !important;
  height: 40px;
  color: $color-white;
  border-radius: 4px;
  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    min-width: 300px !important;
  }
  &:hover,
  &:focus,
  &:focus-within {
    border-color: $color-text-disabled;
    border-width: 1px !important;
    box-shadow: none;
  }
  input {
    &:placeholder-shown,
    &::placeholder {
      color: $color-text-disabled !important;
    }
  }
}
