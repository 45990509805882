.ant-form {
  .ant-form-item-label {
    > label {
      @apply relative;
      font-size: 14px;
      &.ant-form-item-required {
        @apply pl-2;
        &:not(.ant-form-item-required-mark-optional)::before {
          @apply absolute top-[6px] -left-0;
          display: block !important;
        }
      }
    }
  }

  input.ant-input,
  .ant-select,
  .ant-picker {
    min-height: 40px !important;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-select-multiple {
    .ant-select-selector {
      min-height: 40px !important;
    }
  }

  .ant-select-selection-overflow {
    @apply gap-1;
  }
}
