.custom_date_schedule{
    .ant-picker-input{
        display: none;
    }
   .ant-picker-outlined{
        background:none !important;
        border-width: none !important;
        border-style: none !important;
        border-color: none !important;
    }
}