.overview {
    .ant-input-group-addon{
        position: absolute !important;
        top: 10%;
        z-index: 1;
        background-color: transparent !important;
        padding-left: 10px !important;
        border-color: red !important;
        color: #666666 !important;
    
    }
    .ant-input-search-button{
        border: none !important;
    }
    .ant-input-outlined{
        padding: 3px 40px !important;
        min-width: 425px ;
        height: 40px;
        background-color: #FAFAFA !important;
        border-radius: 8px !important;
        
    }
    .ant-input-search-button:hover {
        background-color: transparent !important;
        border: none !important;
    }
    .ant-input-outlined::placeholder{
        color: #666666 !important;
        font-family: var(--font-family);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: left;
        font-variation-settings: 'wdth' 100;
    }
    .ant-tabs-nav::before{
        display: none;
    }
    .ant-tabs-tab {
        font-family: var(--font-family);
        color: #666666; 
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        align-items: center;
      }
      .ant-tabs-tab:hover {
        color: #4759FF !important; 
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #4759FF !important;
    }
    .ant-tabs-ink-bar {
        border :1px solid #4759FF !important;
    }
    .ant-pagination {
        display: flex !important;
        justify-content: center;
    }
}




