.ant-table-thead {
  @apply text-sm font-medium;
  height: var(--table-row-height) !important;

  > tr > th {
    @apply font-medium;
    vertical-align: middle;
    padding: 16px !important;
    background: var(--table-head-color) !important;
    &:first-child {
      border-top-left-radius: 6px !important;
    }
    &:last-child {
      border-top-right-radius: 6px !important;
    }

    &::before {
      display: none;
    }
  }
}

.ant-table-body {
  @apply text-sm;
  max-height: 498px !important;
  min-height: 498px !important;
  overflow-y: auto !important;
}

.table--col-action {
  .ant-btn {
    padding: 0 4px;
  }
}

.ant-pagination-total-text,
.ant-pagination-prev,
.ant-pagination-next {
  display: flex !important;
  align-items: center !important;
}
