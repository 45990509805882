.schedule_custom{
    .ant-picker-outlined{
        background: none !important;
        border:none !important
        }
    .ant-picker-input {
        input{
            display: none !important;
        }
    }
        
}