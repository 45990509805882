.header__logo {
  background-image: url('/assets/images/logo.svg');
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $color-primary;
  height: 56px;
}

.ant-layout {
  @apply bg-gray-dark-1;
  &-header {
    height: 56px;
    line-height: normal;
  }
  &-sider {
    background: $color-white !important;
    box-shadow: 2px 4px 5.3px 0px rgba(0, 0, 0, 0.15);
    .sider__switch {
      right: -10px;
      z-index: 1;
      cursor: pointer;
      display: flex;
      align-items: center;
      height: calc(100vh - 56px);
      top: 56px;
      bottom: 0;
    }

    &-collapsed {
      .header__logo {
        background-size: contain;
      }
    }

    .ant-menu-item {
      @apply w-full;
      margin: 8px 0;
      padding-left: 12px !important;
      &::before {
        content: '';
        @apply absolute top-0 left-0 w-[2px] h-full;
      }
      .ant-menu-title-content {
        margin-left: 12px;
      }
      &.ant-menu-item-selected {
        &:before {
          @apply bg-red;
        }
      }
    }

    .ant-menu-inline-collapsed {
      .ant-menu-title-content {
        display: none !important;
      }
      .ant-menu-item {
        padding-left: initial !important;
        padding-inline: initial;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.ant-breadcrumb {
  @apply bg-white;
}
