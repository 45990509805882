.popup_pconfirm{
    .ant-modal-content{
        border-radius: 16px;
       
    }
   
    .ant-modal-footer{
        display: none;
    }
    .custom_footer{
        display: flex;
        width: 100%;
        gap: 10px;
    }
    .btn-cancle{
        flex-basis: 50%;
        color: #15192080;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        border-radius: 8px;
        border:1px solid #56678942;
        height: 42px;
        
    }
    .btn-cancle:hover{
        flex-basis: 50%;
        color: #D7242F;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        border-radius: 8px;
        border:1px solid #D7242F;
        height: 42px;
        
    }
    .btn-confirm{
        flex-basis: 50%;
        color: white;
        background-color: #D7242F;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        border-radius: 8px;
        border:1px solid #D7242F;
        height: 42px;
    }
    .ant-row{
        display: flex;
    
    }
    textarea{
        height: 100px !important;
        border-radius: 8px;
        
        
    }

}