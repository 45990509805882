.ant-upload {
  width: 194px !important;
  height: 194px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed rgba(0, 0, 0, 0.1); /* Thay đổi loại border nếu cần */
  border-radius: 2px;
  overflow: hidden;
}

.upload-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.upload-remove-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  background: white;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 4px;
  z-index: 1;
}

.upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  height: 100%;
  width: 100%;
}
