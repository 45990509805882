.login {
  &__container {
    input.ant-input {
      min-height: initial !important;
    }
    .ant-input-affix-wrapper input.ant-input {
      height: 100% !important;
    }

    .ant-btn-primary.ant-btn-dangerous {
      background: rgba(215, 36, 47, 1) !important;
    }
  }

  &__form {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 1);
  }

  img {
    max-height: calc(100vh - 56px);
  }
}
