.utils-editor {
  .ck {
    &-editor {
      &__editable {
        height: 100%;
        min-height: 150px;
      }
    }
  }
}
