.candidate_create{
    .ant-picker-header-prev-btn ,.ant-picker-header-next-btn ,.ant-picker-header-super-next-btn ,.ant-picker-header-super-prev-btn{
        color: #D7242F !important;
    }
    .ant-picker-footer{
      border-top: none !important;
    }
    
    .custom_date{
      .ant-picker-suffix {
        display: none;
      }
      .ant-picker{
        width: 70px;
        height: 35px;
        border-radius: 6px;
        color: #000000;
        font-style: 22px;
        line-height: 28px;
        font-weight: 400;
        background-color: #7676801F;
      } 
      
    }
  }
  .create_mail{
    
    .ant-form .ant-select-multiple .ant-select-selector{
      height: 40px !important;
      padding: 0;
      
    }
    .ant-select-selection-overflow{
      height: 100%;
      padding: 2px;
      flex-wrap: nowrap;
      
    }

    .ant-select-selection-item{
      background-color: white !important;
      position: relative;
      height: 100%;
    
    }
    .ant-select-selection-overflow-item{
     margin-right:-15px ;
    }
    .ant-select-selection-item-remove{
      position: absolute;
      right:15px;
      top: calc(50% - 5px);
    }

  }
  .ant-picker-now-btn {
      color: #D7242F !important;
      font-size: 14px;
      font-weight: 500;
    }
  .ant-btn-sm{
    background-color: #D7242F;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
  }
  .ant-form-item-required::before{
   display: none !important;

  }