.ck {
  &-editor {
    &__editable {
      height: 250px;
    }
  }
  &-content {
    padding-left: 20px !important;
    &:has(ul) {
      padding-left: 22px !important;
    }
    &:has(ol) {
      padding-left: 22px !important;
    }
  }
  ul {
    list-style: initial;
    padding-left: 30px;
  }

  ol {
    list-style: number;
    padding-left: 30px;
  }

  h1,
  h2,
  h3,
  h4 {
    font-size: 22px;
    font-weight: 500;
  }

  a {
    color: gray;
  }
}

.ant-table-cell {
  ul {
    list-style: initial;
    padding-left: 30px;
  }

  ol {
    list-style: number;
    padding-left: 30px;
  }

  a {
    color: gray;
  }
}
