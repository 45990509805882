@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Roboto', system-ui, sans-serif;
  }
}

@import './variables';
@import './overide/index';
@import './modules/index';
@import './overide/upload';

body {
  font-family: $font-family !important;
  font-size: $font-size;
  color: $color-text-black !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: $color-text-disabled;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: $color-text-disabled;
}

.loading {
  height: calc(100vh - 64px);
}
