.popup_filter{
    .ant-modal-content{
        padding: 0  !important;
        width: 560px;
       height: 300px;
        border-radius: 8px ;
        overflow: hidden;
       
    }
    .ant-modal-close{
        display: none;
    }
    .ant-form-item-row{
        display: flex;
        flex-direction: column;
    }
    .ant-form-item-label{
        display: flex;
        justify-content: start;

    }
    .ant-modal-footer{
        display: none;
    }
    .ant-radio-group-outline{
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
    
    .ant-radio-button{
        background-color:white !important;
        border: 1px solid #00000026 !important;
        border-radius: 14px;
        border: none;
    }
    .ant-radio-button-checked  {
        background-color:#D7242F1A !important;
        border: 1px solid #00000026 !important;
        border-radius: 14px;
        border: none;
    }
    .ant-radio-button-wrapper{
        z-index: 1;
        border: none;
        
      
    }
    .ant-radio-button-wrapper:not(:first-child)::before{
        display: none;
    }

   .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    border: none;
   }
   .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    display: none ;
   }
   
}

