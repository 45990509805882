$color-primary: #d7242f;
$color-white: #ffffff;
$color-text-black: #000000d9;
$color-text-primary: #d7242f;
$color-bg-acitve: rgba(215, 36, 47, 0.22);
$color-text-disabled: rgba(204, 204, 204, 1);
$font-family: "Roboto", sans-serif;
$font-size: 16px;

:root {
  --color-primary: $color-primary;
  --color-white: $color-white;
  --table-row-height: 56px;
  --color-text-primary: $color-text-primary;
  --color-text-black: $color-text-black;
  --color-text-disabled: $color-text-disabled;
  --table-head-color: rgba(221, 221, 221, 1);
}
